import React, { useEffect, useState } from 'react'
import { useRef } from 'react';
import { RxHamburgerMenu } from "react-icons/rx";

const Header = () => {

  const [isHeaderOpen, setIsHeaderOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsHeaderOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const onIconClick = () => {
    setIsHeaderOpen(!isHeaderOpen);
  };

  return (
    <div className='text-black font-roboto fixed w-full top-0 z-10'>
      <div className='flex flex-row justify-end md:justify-center items-center p-2 md:p-3'>
        <RxHamburgerMenu onClick={onIconClick} className='text-3xl md:hidden'/>
        <div className='fixed top-[5vh] right-0 z-30'>
          {!isHeaderOpen? <></> : <div ref={dropdownRef} className='bg-white w-[200px] animate-fade animate-once animate-duration-500 animate-ease-in'>
            <ul className='divide-y text-lg font-roboto flex flex-col border border-black'>
              <a href="#about" className='px-2 py-1 text-lg'>
                About
              </a>
              <a href="#articles" className='px-2 py-1 text-lg'>
                Articles
              </a>
              <a href="#skills" className='px-2 py-1 text-lg'>
                Skills
              </a>
              <a href="#experience" className='px-2 py-1 text-lg'>
                Experience
              </a>
              <a href="#projects" className='px-2 py-1 text-lg'>
                Projects
              </a>
              <a href="#contact" className='px-2 py-1 text-lg'>
                Contact
              </a>
            </ul>
          </div>}
        </div>
        <div className='hidden md:block bg-slate-700 text-white px-6 rounded-full py-2 '>
          <a href="#about" className='px-2 text-lg'>
            About
          </a>
          
          <a href="#articles" className='px-2 text-lg'>
            Articles
          </a>
          <a href="#skills" className='px-2 text-lg'>
            Skills
          </a>
          <a href="#experience" className='px-2 text-lg'>
            Experience
          </a>
          <a href="#projects" className='px-2 text-lg'>
            Projects
          </a>
          <a href="#contact" className='px-2 text-lg'>
            Contact
          </a>
        </div>
      </div>
    </div>
  )
}

export default Header