import React from 'react'

const About = () => {
  return (
    <div id="about" className='rounded-xl text-black p-2 font-roboto text-base mx-auto w-5/6 md:w-2/3 my-5'>
      <div className='font-bold text-lg'>A little about myself... </div>
      I studied Computer Science at the University of Toronto 
      with a specialist in Software Engineering.
      I've worked in several industries from finance, to healthcare, and have experience
      in both the startup and enterprise level.
      In 2020 I got a job as a Software Developer at the University Health Network. There I worked 
      mainly in JavaScript to create NodeJS applications that retrieved data from different REST APIs
      for researchers to leverage. <br/> 
      After that I got an internship at a startup called Ctrlfyp where I was a Software Developer. There
      I mainly used tools like React to build web interfaces as they required, as well as help setup 
      automation pipelines using CircleCI and Github Actions. <br/>
      After working as a Software Developer I got a position as a DevOps Engineer for the Healthcare of 
      Ontario Pension Plan. There I worked in a large team to support business and software solutions using
      tools such as Terraform, AWS Cloud, and Azure DevOps, to build infrastructure that supported the 
      organizations needs. 
      <br/>
      Currently, I am working on developing a financial literacy application called Prospero One
      for Canadians that aims at delivering financial content through a variety of mediums.
      <br/>
      Aside from coding and tech I enjoy playing video games occasionally, and spending time with my 
      family and cat, but also enjoy an adventure outside from time to time.
    </div>
  )
}

export default About