import React from 'react'

const Blurb = () => {
  return (
    <div className='mx-auto w-5/6 md:w-1/2 pt-[8vh] p-2 text-black rounded-xl font-roboto text-md flex flex-col md:flex-row'>
      <img alt="profile" className='rounded-full mx-auto w-[150px] h-[150px] object-cover flex flex-row justify-center items-center' src="devendra.JPG"></img>
      <div className='flex flex-col items-center justify-center px-4 py-4 text-3xl animate-in zoom-in duraton-1000'>
        Hi, I'm Devendra <br></br>
      </div>
    </div>
  )
}

export default Blurb