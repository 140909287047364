import React from 'react'

const Contact = () => {
  return (
    <div id="contact" className='flex flex-col rounded-xl text-black p-2 font-roboto text-base md:text-lg lg:text-xl mx-auto w-5/6 md:w-2/3 mt-5 pb-5'>
      <div className='text-lg md:text-xl lg:text-2xl font-bold'>Contacts / Socials</div>
      <a href="https://github.com/DevendraK25">Github: https://github.com/DevendraK25</a>
      <a href="https://medium.com/@devendra-kulk">Medium: https://medium.com/@devendra-kulk/</a>
    </div>
  )
}

export default Contact