import React from 'react'

const PongBoard = () => {
  return (
    <div className='w-[80vw] h-[50vh] max-w-[700px] max-h-[400px] mx-auto bg-slate-600'>

    </div>
  )
}

export default PongBoard