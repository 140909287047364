import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const ProjectCarousel = () => {
  return (
    <div id="projects" className='w-5/6 md:w-2/3 mx-auto p-2 bg-gray-100 rounded-xl'>
      <h1 className='text-black font-roboto text-lg md:text-xl lg:text-2xl font-bold'>
        Projects:
      </h1>
      <Carousel autoPlay="true" infiniteLoop="true" interval="10000"
        className=' text-center rounded-xl'>
        <div className='flex flex-col lg:flex-row'>
          <img alt="Prospero One" src="/carousel/ProsperoOne.jpg" className='p-4 max-w-[400px] max-h-[500px] md:max-h-[750px] mx-auto lg:mx-0' />
          <p className='flex flex-row text-lg justify-center items-center px-4 font-bold'>
            Prospero One is a financial literacy application that provides articles and a number of tools
            to its users in order to teach them about the intricacies of the Canadian financial system.
          </p>
        </div>
        <div className='flex flex-col lg:flex-row'>
          <img alt="1942" src="/carousel/1942.png" className='p-4 max-w-[400px] max-h-[500px] md:max-h-[750px] mx-auto lg:mx-0' />
          <p className='flex flex-row text-lg justify-center items-center px-4 font-bold'>
            1942 is a Java based application that utilizes object oriented programming principles such
            as Polymorphism and Inheritance in order to define and use various assets in the game, including
            the player, enemies, powerups, etc.
          </p>
        </div>
        <div className='flex flex-col lg:flex-row'>
          <img alt="Wonky Chess" src="/carousel/WonkyChess.jpg" className='p-4 max-w-[400px] max-h-[500px] md:max-h-[750px] mx-auto lg:mx-0' />
          <p className='flex flex-row text-lg justify-center items-center px-4 font-bold'>
            Wonky Chess is a MERN stack based application that allows users to play against a "wonky" AI. 
            The user's games against the AI are stored in a MongoDB database for further analysis down the line.
          </p>
        </div>
        <div className='flex flex-col lg:flex-row'>
          <img alt="Fanlinc" src="/carousel/Fanlinc.jpg" className='p-4 max-w-[400px] max-h-[500px] md:max-h-[750px] mx-auto lg:mx-0' />
          <p className='flex flex-row text-lg justify-center items-center px-4 font-bold'>
            Fanlinc is a MEAN stack application that was developed in a team of 7. This application allows users
            to find groups of individuals interested in the same hobbies as them.
          </p>
        </div>
        <div className='flex flex-col lg:flex-row'>
          <img alt="Fanlinc" src="/carousel/Deeplens.jpg" className='p-4 max-w-[400px] max-h-[500px] md:max-h-[750px] mx-auto lg:mx-0' />
          <p className='flex flex-row text-lg justify-center items-center px-4 font-bold'>
            The AWS Deeplens project is a project written for the University Health Network to help 
            remotely monitor patient health by collecting various metrics on a patient through the 
            AWS Deeplens images, AWS Rekognition AI, and storing the results in DynamoDB. 
          </p>
        </div>
      </Carousel>

    </div>
  )
}

export default ProjectCarousel