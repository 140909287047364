import React from 'react'

const Skills = () => {
  return (
    <div id="skills" className='rounded-xl text-black p-2 font-roboto text-base mx-auto w-5/6 md:w-2/3 my-5'>
      <div className='font-bold text-lg md:text-xl lg:text-2xl'>Skills</div>
      <div className='grid grid-cols-4 md:grid-cols-8 gap-10'>
        <a href="https://aws.amazon.com/" className='flex flex-row justify-center items-center'>
          <img alt="aws" src="/skills/aws.png"></img>
        </a>
        <a href='https://www.heroku.com/' className='flex flex-row justify-center items-center'>
          <img alt="heroku" src="/skills/heroku.png"></img>
        </a>
        <a href='https://www.terraform.io/' className='flex flex-row justify-center items-center'>
          <img alt="terraform" src="/skills/terraform.png"></img>
        </a>
        <a href="https://circleci.com/" className='flex flex-row justify-center items-center'>
          <img alt="circleci" src="/skills/circleci.png"></img>
        </a>
        <a href="https://www.python.org/" className='flex flex-row justify-center items-center'>
          <img alt="python" src="/skills/python.png"></img>
        </a>
        <a href="https://www.java.com/en/" className='flex flex-row justify-center items-center'>
          <img alt="java" src="/skills/java.png"></img>
        </a>
        <a href="https://www.javascript.com/" className='flex flex-row justify-center items-center'>
          <img alt="javascript" src="/skills/javascript.png"></img>
        </a>
        <a href="https://www.w3schools.com/html/" className='flex flex-row justify-center items-center'>
          <img alt="HTML" src="/skills/HTML.png"></img>
        </a>
        <a href="https://react.dev/" className='flex flex-row justify-center items-center'>
          <img alt="react" src="/skills/react.png"></img>
        </a>
        <a href="https://tailwindcss.com/" className='flex flex-row justify-center items-center'>
          <img alt="tailwind" src="/skills/tailwind.png"></img>
        </a>
        <a href="https://angular.io/" className='flex flex-row justify-center items-center'>
          <img alt="angular" src="/skills/angular.png"></img>
        </a>
        <a href="https://azure.microsoft.com/en-ca/products/devops" className='flex flex-row justify-center items-center'>
          <img alt="azuredevops" src="/skills/azuredevops.png"></img>
        </a>
        <a href="https://wordpress.com/" className='flex flex-row justify-center items-center'>
          <img alt="wordpress" src="/skills/wordpress.png"></img>
        </a>
        <a href="https://git-scm.com/" className='flex flex-row justify-center items-center'>
          <img alt="git" src="/skills/git.png"></img>
        </a>
      </div>
      
    </div>
  )
}

export default Skills