import React from 'react'

const Experience = () => {
  return (
    <div id="experience" className='rounded-xl text-black p-2 font-roboto text-base mx-auto w-5/6 md:w-2/3 my-5'>
      <div className='font-bold text-lg md:text-xl lg:text-2xl'>Experience</div>
      <div className='p-4'>
        <div className='flex flex-col md:flex-row justify-between py-2'>
          <h1 className='font-bold text-lg'>Software Engineering Consultant - FDM Group</h1>
          <h1>June 2024 - Present</h1>
        </div>

        <div className='flex flex-col md:flex-row justify-between py-2'>
          <h1 className='font-bold text-lg'>DevOps Engineer - HOOPP</h1>
          <h1>May 2021 - December 2021</h1>
        </div>

        <div className='flex flex-col md:flex-row justify-between py-2'>
          <h1 className='font-bold text-lg'>Software Engineer - Ctrlfyp</h1>
          <h1>January 2021 - May 2021</h1>
        </div>

        <div className='flex flex-col md:flex-row justify-between py-2'>
          <h1 className='font-bold text-lg'>Software Engineer - University Health Network</h1>
          <h1>January 2020 - September 2020</h1>
        </div>
      </div>

    </div>

  )
}

export default Experience