import React from 'react'
import PongBoard from './pong_board/PongBoard'

const Pong = () => {
  return (
    <div className='py-2'>
        <PongBoard></PongBoard>
    </div>
  )
}

export default Pong