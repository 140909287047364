import About from './components/about/About';
import Articles from './components/articles/Articles';
import Blurb from './components/blurb/Blurb';
import Contact from './components/contact/Contact';
import Experience from './components/experience/Experience';
import Header from './components/header/Header';
import Pong from './components/pong/Pong';
import ProjectCarousel from './components/project_carousel/ProjectCarousel';
import Skills from './components/skills/Skills';

function App() {
  return (
    <div className='min-h-screen bg-slate-200'>
      <Header/>
      <Blurb/>
      {/* <Pong/> */}
      <About/>
      <Articles/>
      <Skills/>
      <Experience/>
      <ProjectCarousel/>
      <Contact/>
    </div>
  );
}

export default App;
