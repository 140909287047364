import React from 'react'

const Articles = () => {
  return (
    <div id="articles" className='rounded-xl text-black p-2 font-roboto text-base mx-auto w-5/6 md:w-2/3 my-5'>
      <h1 className='font-bold text-lg md:text-2xl'>Articles</h1>
      <div className='max-h-[700px] overflow-y-auto'>
        <div className='max-w-[700px] mx-auto'>
          <a href="https://devendra-kulk.medium.com/how-to-deploy-your-mern-stack-project-onto-aws-using-custom-domain-ec2-nginx-route-53-ssl-b321c11c870f">
            <div className='flex flex-col border shadow-xl mx-auto p-2 max-w-[700px] bg-white rounded-xl'>
              <h1 className='font-light'>Devendra Kulkarni</h1>
              <div className='flex flex-col md:flex-row'>
                <div className='flex flex-col w-full md:w-3/4 pr-0 md:pr-24'>
                  <h1 className='font-bold text-xl'>
                    How to deploy your MERN stack project onto AWS using custom domain (EC2, Nginx, Route 53, SSL)
                  </h1>
                  <h4 className='font-light'>
                    As someone who has been constantly learning AWS through several attempts. I can still say to myself, that it’s very daunting to t..
                  </h4>
                </div>
                <img alt="medium_aws_image" className='w-1/2 md:w-1/4' src="/articles/medium_aws_deployment.JPG">
                </img>
              </div>
              <div className='flex flex-row pt-2'>
                <h4 className='text-sm font-light pr-2'>9 min read</h4>
                <h4 className='text-sm font-light pl-2'>Feb 1, 2024</h4>
              </div>
            </div>
          </a>
        </div>
        <div className='max-w-[700px] mx-auto my-4'>
          <a href="https://devendra-kulk.medium.com/the-java-memory-model-214b426533c6">
            <div className='flex flex-col border shadow-xl mx-auto p-2 max-w-[700px] bg-white rounded-xl'>
              <h1 className='font-light'>Devendra Kulkarni</h1>
              <div className='flex flex-col md:flex-row'>
                <div className='flex flex-col w-full md:w-3/4 pr-0 md:pr-24'>
                  <h1 className='font-bold text-xl'>
                    The Java Memory Model
                  </h1>
                  <h4 className='font-light'>
                    When it comes to programming learning about the memory model is a fundamental aspect that often seems daunting to new programmers.
                  </h4>
                </div>
                <img alt="medium_aws_image" className='w-1/2 md:w-1/4 max-h-[150px]' src="/articles/medium_java_icon.png">
                </img>
              </div>
              <div className='flex flex-row pt-2'>
                <h4 className='text-sm font-light pr-2'>5 min read</h4>
                <h4 className='text-sm font-light pl-2'>Aug 5, 2024</h4>
              </div>
            </div>
          </a>
        </div>
        <div className='max-w-[700px] mx-auto my-4'>
          <a href="https://devendra-kulk.medium.com/java-basics-cheats-9ee44972da9f">
            <div className='flex flex-col border shadow-xl mx-auto p-2 max-w-[700px] bg-white rounded-xl'>
              <h1 className='font-light'>Devendra Kulkarni</h1>
              <div className='flex flex-col md:flex-row'>
                <div className='flex flex-col w-full md:w-3/4 pr-0 md:pr-24'>
                  <h1 className='font-bold text-xl'>
                    Java Basics Cheatsheet
                  </h1>
                  <h4 className='font-light'>
                    This gonna be aimed towards people who have programming experience and need to brush up on syntax for any number of reasons. However, anyone is free to use this for any intended purpose they like.
                  </h4>
                </div>
                <img alt="medium_aws_image" className='w-1/2 md:w-1/4 max-h-[150px]' src="/articles/medium_java_icon.png">
                </img>
              </div>
              <div className='flex flex-row pt-2'>
                <h4 className='text-sm font-light pr-2'>7 min read</h4>
                <h4 className='text-sm font-light pl-2'>Aug 5, 2024</h4>
              </div>
            </div>
          </a>
        </div>
        <div className='max-w-[700px] mx-auto my-4'>
          <a href="https://devendra-kulk.medium.com/java-object-oriented-programming-basics-1-941780fe14d7">
            <div className='flex flex-col border shadow-xl mx-auto p-2 max-w-[700px] bg-white rounded-xl'>
              <h1 className='font-light'>Devendra Kulkarni</h1>
              <div className='flex flex-col md:flex-row'>
                <div className='flex flex-col w-full md:w-3/4 pr-0 md:pr-24'>
                  <h1 className='font-bold text-xl'>
                    Java Object Oriented Programming Basics #1
                  </h1>
                  <h4 className='font-light'>
                    If you have seen my previous story about the Java Basics Cheat sheet, this is a direct continuation of that topic. This story will cover...
                  </h4>
                </div>
                <img alt="medium_aws_image" className='w-1/2 md:w-1/4 max-h-[150px]' src="/articles/medium_java_icon.png">
                </img>
              </div>
              <div className='flex flex-row pt-2'>
                <h4 className='text-sm font-light pr-2'>4 min read</h4>
                <h4 className='text-sm font-light pl-2'>Aug 7, 2024</h4>
              </div>
            </div>
          </a>
        </div>
        <div className='max-w-[700px] mx-auto my-4'>
          <a href="https://medium.com/@devendra-kulk/java-object-oriented-programming-basics-2-4efd6761521d">
            <div className='flex flex-col border shadow-xl mx-auto p-2 max-w-[700px] bg-white rounded-xl'>
              <h1 className='font-light'>Devendra Kulkarni</h1>
              <div className='flex flex-col md:flex-row'>
                <div className='flex flex-col w-full md:w-3/4 pr-0 md:pr-24'>
                  <h1 className='font-bold text-xl'>
                    Java Object Oriented Programming Basics #2
                  </h1>
                  <h4 className='font-light'>
                    In the previous story I introduced the four pillars of OOP. Encapsulation, abstraction, inheritance and polymorphism. 
                  </h4>
                </div>
                <img alt="medium_aws_image" className='w-1/2 md:w-1/4 max-h-[150px]' src="/articles/medium_java_icon.png">
                </img>
              </div>
              <div className='flex flex-row pt-2'>
                <h4 className='text-sm font-light pr-2'>4 min read</h4>
                <h4 className='text-sm font-light pl-2'>Aug 7, 2024</h4>
              </div>
            </div>
          </a>
        </div>
        <div className='max-w-[700px] mx-auto my-4'>
          <a href="https://medium.com/@devendra-kulk/java-data-structures-and-algorithms-0a165c31b9ea">
            <div className='flex flex-col border shadow-xl mx-auto p-2 max-w-[700px] bg-white rounded-xl'>
              <h1 className='font-light'>Devendra Kulkarni</h1>
              <div className='flex flex-col md:flex-row'>
                <div className='flex flex-col w-full md:w-3/4 pr-0 md:pr-24'>
                  <h1 className='font-bold text-xl'>
                    Java Data Structures and Algorithms #1
                  </h1>
                  <h4 className='font-light'>
                  Data Structures are a fundamental aspect of programming, every program can be broken down into concepts... 
                  </h4>
                </div>
                <img alt="medium_aws_image" className='w-1/2 md:w-1/4 max-h-[150px]' src="/articles/medium_java_icon.png">
                </img>
              </div>
              <div className='flex flex-row pt-2'>
                <h4 className='text-sm font-light pr-2'>4 min read</h4>
                <h4 className='text-sm font-light pl-2'>Aug 8, 2024</h4>
              </div>
            </div>
          </a>
        </div>
        <div className='max-w-[700px] mx-auto my-4'>
          <a href="https://medium.com/@devendra-kulk/java-data-structures-and-algorithms-2-9f2afff66f84">
            <div className='flex flex-col border shadow-xl mx-auto p-2 max-w-[700px] bg-white rounded-xl'>
              <h1 className='font-light'>Devendra Kulkarni</h1>
              <div className='flex flex-col md:flex-row'>
                <div className='flex flex-col w-full md:w-3/4 pr-0 md:pr-24'>
                  <h1 className='font-bold text-xl'>
                    Java Data Structures and Algorithms #2
                  </h1>
                  <h4 className='font-light'>
                    In the previous story I discussed about the different common data structures used in Java programming...
                  </h4>
                </div>
                <img alt="medium_aws_image" className='w-1/2 md:w-1/4 max-h-[150px]' src="/articles/medium_java_icon.png">
                </img>
              </div>
              <div className='flex flex-row pt-2'>
                <h4 className='text-sm font-light pr-2'>4 min read</h4>
                <h4 className='text-sm font-light pl-2'>Aug 8, 2024</h4>
              </div>
            </div>
          </a>
        </div>
      </div>

    </div>
  )
}

export default Articles